import React from "react";
import "./index.scss";

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div class="">
          <div class="document">
            <h1>Privacy Policy for Byte Technology Systems Inc.</h1>

            <p>
              At Byte Virtual Cafeteria, accessible from
              https://business.getbyte.app/#/en, one of our main priorities is
              the privacy of our visitors. This Privacy Policy document contains
              types of information that is collected and recorded by Byte's
              Virtual Cafeteria website and how we use it. If you have
              additional questions or require more information about our Privacy
              Policy, do not hesitate to contact us. This Privacy Policy applies
              only to our online activities and is valid for visitors to our
              website with regards to the information that they shared and/or
              collect in Byte's Virtual Cafeteria website or third party online
              channels. This policy is not applicable to any information
              collected offline. We'll use your information to contact you about
              our services and products.
            </p>

            <h3>Consent</h3>

            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>

            <h3>Information we collect</h3>

            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information. If
              you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide. When you register
              for an Account, we may ask for your contact information, including
              items such as name, company name, address, email address, and
              telephone number.
            </p>

            <h3>How we use your information</h3>

            <p>
              We use the information we collect in various ways, including to:
            </p>

            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
            <p></p>
            <h3>Log Files</h3>

            <p>
              Byte's Virtual Cafeteria website follows a standard procedure of
              using log files. These files log visitors when they visit
              websites. All hosting companies do this and a part of hosting
              services' analytics. The information collected by log files
              include internet protocol (IP) addresses, browser type, Internet
              Service Provider (ISP), date and time stamp, referring/exit pages,
              and possibly the number of clicks. These are not linked to any
              information that is personally identifiable. The purpose of the
              information is for analyzing trends, administering the site,
              tracking users' movement on the website, and gathering demographic
              information.
            </p>

            <h3>Cookies and Web Beacons</h3>

            <p>
              Like any other website, Byte's Virtual Cafeteria website uses
              "cookies". These cookies are used to store information including
              visitors' preferences, and the pages on the website that the
              visitor accessed or visited. The information is used to optimize
              the users' experience by customizing our web page content based on
              visitors' browser type and/or other information.
            </p>

            <h3>Advertising Partners Privacy Policies</h3>

            <p>
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of Byte Virtual Cafeteria. Third-party ad
              servers or ad networks uses technologies like cookies, JavaScript,
              or Web Beacons that are used in their respective advertisements
              and links that appear on Byte Virtual Cafeteria, which are sent
              directly to users' browser. They automatically receive your IP
              address when this occurs. These technologies are used to measure
              the effectiveness of their advertising campaigns and/or to
              personalize the advertising content that you see on websites that
              you visit. Note that Byte Virtual Cafeteria has no access to or
              control over these cookies that are used by third-party
              advertisers.
            </p>

            <h3>Third Party Privacy Policies</h3>

            <p>
              Byte Virtual Cafeteria's Privacy Policy does not apply to other
              advertisers or websites. Thus, we are advising you to consult the
              respective Privacy Policies of these third-party ad servers for
              more detailed information. It may include their practices and
              instructions about how to opt-out of certain options. You can
              choose to disable cookies through your individual browser options.
              To know more detailed information about cookie management with
              specific web browsers, it can be found at the browsers' respective
              websites.
            </p>

            <h3>Children's Information</h3>

            <p>
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity. Byte's Virtual Cafeteria website does not knowingly
              collect any Personal Identifiable Information from children under
              the age of 13. If you think that your child provided this kind of
              information on our website, we strongly encourage you to contact
              us immediately and we will do our best efforts to promptly remove
              such information from our records.
            </p>

            <h3>Changes to This Privacy Policy</h3>

            <p>
              We may update our Privacy Policy from time to time. Thus, we
              advise you to review this page periodically for any changes. We
              will notify you of any changes by posting the new Privacy Policy
              on this page. These changes are effective immediately, after they
              are posted on this page. Our Privacy Policy was created with the
              help of the&nbsp;
              <a href="https://www.termsfeed.com/privacy-policy-generator/">
                <u>Privacy Policy Generator</u>
              </a>
            </p>

            <h3>Contact Us</h3>

            <p>
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us.
            </p>
          </div>
        </div>
      </>
    );
  }
}
export default Privacy;
