import React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import './index.scss';
import sliderImage from '../../assets/sliderImage.png';
import playStore from '../../assets/google2.svg';
import btmplayStore from '../../assets/google.svg';
import appStore from '../../assets/badge_appstore-lrg.svg';
import arrowDown from '../../assets/arrowDown.png';
import browserIcon from '../../assets/browserIcon.png';
import qualityFood from '../../assets/qualityFood.png';
import superTaste from '../../assets/superTaste.png';
import fastDelivery from '../../assets/fastDelivery.png';
import { Carousel } from 'react-carousel-minimal';
import mainimage from '../../assets/crustys.jpg';
import Resturants from './resturants';
import c1 from '../../assets/c1.jpg'
import c2 from '../../assets/c2.jpg'
import c3 from '../../assets/c3.JPG'
import c5 from '../../assets/c7.jpg'
import c6 from '../../assets/c6.JPG'
import heroimg from '../../assets/smackin.jpeg'


import selling1 from '../../assets/selling1.png';
import selling2 from '../../assets/selling2.png';
import selling3 from '../../assets/selling3.png';
import selling4 from '../../assets/selling4.png';
import selling5 from '../../assets/selling5.png';
import selling6 from '../../assets/selling6.png';
import selling7 from '../../assets/selling7.png';
import selling8 from '../../assets/selling8.png';
import customerSays from '../../assets/customerSays.png';
import chefIcon from '../../assets/chef-img.png';
import playStoreIcon from '../../assets/playStoreIcon.png';
import appStoreIcon from '../../assets/appStoreIcon.png';
import { ThirtyFpsRounded } from '@mui/icons-material';



class HomePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            
            carouselImgs: [{
                image: c1,
                //  caption: 'Caption Text'
                },
                {
                image: c2,
                },
                {
                image: c3,
                },
                {
                image: c5,
                },
                {
                image: c6,
                }]
        }
    }

    // const 


    componentDidMount() {
    }
    

    render() {
        return (
            <div className='main'>
  
                <div className='main-banner-div' style={{backgroundImage: `url(${heroimg})`}} id="mainBannerSection">
                    <Grid container direction="row" spacing={3}>

                            <div className='main-banner-content'>

                                <Typography className='content-1'>Freshness in</Typography>
                                <Typography className='content-2'>Every Bite</Typography>

                                <Typography className='content-3'>Order Now <img src={arrowDown} alt="" /></Typography>
                                <Grid container direction="row" spacing={2} sx={{ justifyContent: { xs: "center", sm: "center", md: "left" } }}>
                                    <Grid item>
                                            <Button variant='contained' onClick={()=> window.open("https://web.thebyte.app/en/", "_blank")} className='download-app'><img src={browserIcon} alt="" /></Button>

                                    </Grid>
                                    <Grid item>
                                        <a href='https://play.google.com/store/apps/details?id=com.byte.customer' style={{ textDecuration: "none" }}>
                                            <img className='store-image' src={playStore} alt="" />
                                         </a>

                                    </Grid>
                                    <Grid item>
                                        <a href=' https://apps.apple.com/pk/app/byte-food-delivery/id1487913443' style={{ textDecuration: "none" }}>
                                            <img className='store-image' src={appStore} alt="" />
                                        </a> 
                                    </Grid>
                                </Grid>
                            </div>
                        {/* <Grid item md={5} lg={5} xs={12} sm={12} m="auto" style={{ textAlign:"center" }}>
                            <img className='main-banner-image' src={sliderImage} alt="" />
                        </Grid> */}
                    </Grid>
                </div>
                              {/* <div className='flex'>
                        <img src={heroimg} className='hero'alt=""/>
                </div> */}

                {/* <div className='service-section' id="serviceSection">
                    <Typography className='main-heading'>We Offer <span className='heading-part'>Best Services</span></Typography>
                    <Typography className='sub-heading'>lorem ipsum dolalr sit lorem ipsum dolalr sitlorem ipsum dolalr.</Typography>
                    <Grid container justifyContent="" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" }, mt: 3 }} spacing={2}>
                        <Grid item xs={10} md={3} lg={3} m='auto'>
                            <Grid container direction="column">
                                <Grid item className='content-center'>
                                    <img src={qualityFood} alt="" className='img' />
                                </Grid>
                                <Grid item className='content-center'>
                                    <Typography className='img-heading'>Quality Food</Typography>
                                </Grid>
                                <Grid item className='content-center'>
                                    <Typography className='img-heading-content'>lorem ipsum dolalr sit lorem ipsum dolalr sitlorem dolalr sitl</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={10} md={3} lg={3} m='auto'>
                            <Grid container direction="column">
                                <Grid item className='content-center'>
                                    <img src={superTaste} alt="" className='img' />
                                </Grid>
                                <Grid item className='content-center'>
                                    <Typography className='img-heading'>Super Taste</Typography>
                                </Grid>
                                <Grid item className='content-center'>
                                    <Typography className='img-heading-content'>lorem ipsum dolalr sit lorem ipsum dolalr sitlorem dolalr sitl</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={10} md={3} lg={3} m='auto'>
                            <Grid container direction="column">
                                <Grid item className='content-center'>
                                    <img src={fastDelivery} alt="" className='img' />
                                </Grid>
                                <Grid item className='content-center'>
                                    <Typography className='img-heading'>Fast Delivery</Typography>
                                </Grid>
                                <Grid item className='content-center'>
                                    <Typography className='img-heading-content'>lorem ipsum dolalr sit lorem ipsum dolalr sitlorem dolalr sitl</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div> */}

                <div className='our-resturants' id="ourResturantSection">
                    <Typography className='main-heading'><span className='heading-part'>Restaurants</span></Typography>
                    {/* <Typography className='sub-heading'>lorem ipsum dolalr sit lorem ipsum dolalr sitlorem ipsum dolalr.</Typography> */}
                    <Resturants  is_desktop={this.props.is_desktop} />
                </div>

                {/* <div className='best-sellers' id="bestSellerSection">
                    <Typography className='main-heading'>Best <span className='heading-part'>Selling</span></Typography>
                    <Typography className='sub-heading'>lorem ipsum dolalr sit lorem ipsum dolalr sitlorem ipsum dolalr sitlorem ipsum dolalr sitlorem ipsum dolalr sit</Typography>
                    {
                        !this.props.is_desktop ?
                            <div class="sellers-scrolling-wrapper">
                                {
                                    this.state.best_sellers.map(seller =>
                                        <div className="mobile-seller-paper">
                                            <Grid container direction="column" spacing={1}>
                                                <Grid item>
                                                    <img src={seller.image} className="img" alt="" />
                                                </Grid>
                                                <Grid item>
                                                    <Typography className='name'>{seller.name}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className='description'>{seller.description}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className='price'>Rs. {seller.price}</Typography>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                            </div>
                            :

                            <Grid container direction="row" spacing={1}>
                                {
                                    this.state.best_sellers.map(best => (
                                        <Grid item sm={4} md={3} m="auto">
                                            <div className='selling-card'>
                                                <Grid container direction="column" spacing={1}>
                                                    <Grid item>
                                                        <img src={best.image} className="img" alt="" />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className='name'>{best.name}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className='description'>{best.description}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className='price'>Rs. {best.price}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Grid>

                                    ))
                                }
                            </Grid>
                    }
                </div> */}

                <div className='customer-say' id="customerSaveSection">
                    {

                        <div style={{ textAlign: "center" }}>
        <div className='our-resturants' id="ourResturantSection">
        <Typography className='main-heading'><span className='heading-part'>Customer Testimonial</span></Typography>
        {/* <Typography className='sub-heading'>lorem ipsum dolalr sit lorem ipsum dolalr sitlorem ipsum dolalr.</Typography> */}
        </div>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={this.state.carouselImgs}
            time={2000}
            width="850px"
            height="500px"
            captionStyle={{ fontSize: '2em',
            fontWeight: 'bold',}}
            radius="10px"
            slideNumber={false}
            captionPosition="bottom"
            automatic={true}
            dots={true}
    //        pauseIconColor="white"
    //        pauseIconSize="40px"
            slideBackgroundColor="rgba(100, 100, 100, 0.1)"
            slideImageFit="cover"
            thumbnails={false}
      //      thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "40px auto",
            }}
          />
        </div>
      </div>
                        // !this.props.is_desktop ?
                        //     <Grid container directions="column" spacing={3}>
                        //         <Grid item m="auto">
                        //             <Typography className='heading'>What Our Customer Say <span className='heading-part'>About US</span></Typography>
                        //         </Grid>
                        //         <Grid item m="auto" style={{ textAlign: "center" }}>
                        //             <img className='img' src={customerSays} alt="" />
                        //         </Grid>
                        //         <Grid item m="auto">
                        //             <Typography style={{ textAlign: "center" }} className='description'>This is a type of resturent which typically serves food and drink, in addition to light refreshments such as baked goods or snacks. The term comes frome the rench word meaning food</Typography>
                        //         </Grid>
                        //     </Grid>
                        //     :
                        //     <Grid container directions="row" spacing={3}>
                        //         <Grid item md={6} lg={6}>
                        //             <img className='img' src={customerSays} alt="" />
                        //         </Grid>
                        //         <Grid item md={5} lg={5} m="auto">
                        //             <Typography className='heading'>What Our Customer Say <span className='heading-part'>About US</span></Typography>
                        //             <Typography className='description'>This is a type of resturent which typically serves food and drink, in addition to light refreshments such as baked goods or snacks. The term comes frome the rench word meaning food</Typography>
                        //         </Grid>
                        //     </Grid>
                    }
                </div>

                <div id="getStartedSection" className='get-started-with-byte'>
                    {
                        !this.props.is_desktop ?
                            <Grid container directions="column" spacing={2}>
                                <Grid item m="auto">
                                    <Typography className='heading'>Get Started with <span className='heading-part'>Byte today</span></Typography>
                                </Grid>
                                <Grid item m="auto">
                                    <Typography className='description'>Delicious, hygienic food with a diverse menu made in our state-of-the-art kitchens is just a few clicks away. Download the Byte app now to get started!</Typography>
                                </Grid>
                                <Grid item m="auto">
                                    <Grid container direction="row" spacing={2}>
                                    <Grid item>
                                        <a href='https://play.google.com/store/apps/details?id=com.byte.customer' style={{ textDecuration: "none" }}>
                                            <img className='btm-store-img' src={btmplayStore} alt="" />
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href=' https://apps.apple.com/pk/app/byte-food-delivery/id1487913443' style={{ textDecuration: "none" }}>
                                            <img className='btm-appstore-img' src={appStore} alt="" />
                                        </a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} m="auto" style={{ textAlign: "center" }}>
                                    <img className='chef-img' src={chefIcon} alt="" />
                                </Grid>
                            </Grid>
                            :
                            <Grid container directions="row" spacing={3}>
                                <Grid item md={5} lg={5} m="auto">
                                    <Typography className='heading'>Get Started with <span className='heading-part'>Byte today</span></Typography>
                                    <Typography className='description'>Delicious, hygienic food with a diverse menu made in our state-of-the-art kitchens is just a few clicks away. Download the Byte app now to get started!</Typography>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item>
                                        <a href='https://play.google.com/store/apps/details?id=com.byte.customer' style={{ textDecuration: "none" }}>
                                            <img className='btm-store-img' src={btmplayStore} alt="" />
                                        </a>
                                        </Grid>
                                        <Grid item>
                                        <a href=' https://apps.apple.com/pk/app/byte-food-delivery/id1487913443' style={{ textDecuration: "none" }}>
                                            <img className='btm-appstore-img' src={appStore} alt="" />
                                        </a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} lg={6}>
                                    <img className='chef-img' src={chefIcon} alt="" />
                                </Grid>
                            </Grid>
                    }
                </div>

            </div>
        )
    }
}
export default HomePage;
