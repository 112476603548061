import React from "react";
import Card from 'react-bootstrap/Card';
import flamewok from '../../assets/storefronts/Flaming-Wok.jpg'
import burgettime from '../../assets/storefronts/burger-time.jpg'
import chingari from '../../assets/storefronts/chingari.png'
import crustys from '../../assets/storefronts/crustys.jpg'
import Dum from '../../assets/storefronts/Dum.jpg'
import lazeez from '../../assets/storefronts/lazeez.jpg'
import lettuce from '../../assets/storefronts/lettuce.jpg'
import Melt from '../../assets/storefronts/Melt.jpg'
import nashta from '../../assets/storefronts/nashta.jpg'
import overloaded from '../../assets/storefronts/overloaded.jpg'
import pizzanow from '../../assets/storefronts/pizzanow.jpg'
import rollertoaster from '../../assets/storefronts/rollertoaster.jpg'
import smackin from '../../assets/storefronts/smackin.jpg'
import wingit from '../../assets/storefronts/wingit.jpg'
import moes from '../../assets/storefronts/Moes.jpg'
import biryanisultan from '../../assets/storefronts/biryanisultant.jpg'
import { Typography, CircularProgress, CardMedia, CardContent, Paper, Rating, Grid, autocompleteClasses } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import ResturantApis from "../../apis/ResturantApis";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import "./resturants.scss";
import { margin } from "@mui/system";


class Resturants extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            row1:[{
                store_name: "Flaming Wok",
                logo: flamewok,
                tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                store_rating: 4.5
            },
            {
                store_name: "Moe's Thin Crust",
                logo: moes,
                tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                store_rating: 4.5
            },
            {
                store_name: "Lettuce",
                logo: lettuce,
                tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                store_rating: 4.2
            },
            {
                store_name: "Melt",
                logo: Melt,
                tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                store_rating: 4.2
            },
            {
                store_name: "Chingari Tikka",
                logo: chingari,
                tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                store_rating: 4
            },
            {
                store_name: "Overloaded",
                logo: overloaded,
                tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                store_rating: 4.5
            },],
            row2: [
                
                {
                    store_name: "Crusty's",
                    logo: crustys,
                    tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                    store_rating: 4.2
                },
                {
                    store_name: "Smackin'",
                    logo: smackin,
                    tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                    store_rating: 4.2
                },
                {
                    store_name: "Lazeez",
                    logo: lazeez,
                    tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                    store_rating: 4.2
                },
                {
                    store_name: "Dum",
                    logo: Dum,
                    tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                    store_rating: 4.2
                },
                {
                    store_name: "Nashta Washta",
                    logo: nashta,
                    tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                    store_rating: 4.2
                },
                {
                    store_name: "Roller Toaster",
                    logo: rollertoaster,
                    tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                    store_rating: 4.2
                },
                {
                    store_name: "Just Wing It",
                    logo: wingit,
                    tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                    store_rating: 4.2
                },
                {
                    store_name: "Pizza Now",
                    logo: pizzanow,
                    tags: ['shakes', 'steaks', 'sea food', 'chicken', 'burgers', 'pizzaz'],
                    store_rating: 4.2
                },

            ],
            is_loading: false,
        };
    }

    // componentDidMount() {
    //     this.getResturants()
    // }

    // getResturants = () => {
    //     this.setState({
    //         resturants_data: [],
    //         is_loading: true
    //     })
    //     let resturants = [
    //         "https://web.thebyte.app/en/store/Overloaded---Gulberg/299644",
    //         "https://web.thebyte.app/en/store/Wrap-and-Roll---Gulberg/299647",
    //         "https://web.thebyte.app/en/store/Crustys---Gulberg/299645",
    //         "https://web.thebyte.app/en/store/Pizza-Now---Gulberg/299672",
    //         "https://web.thebyte.app/en/store/Burger-Time---Gulberg/299694",
    //         "https://web.thebyte.app/en/store/Smackin---Gulberg/299643",
    //         "https://web.thebyte.app/en/store/Just-Wing-It---Gulberg/299646",
    //         "https://web.thebyte.app/en/store/Breakfast-247---Gulberg/299739",
    //         "https://web.thebyte.app/en/store/Food-Court---Gulberg/299804",
    //         "https://web.thebyte.app/en/store/Moes-Thin-Crust---Launching-Soon/299846",
    //         "https://web.thebyte.app/en/store/Biryani-Sultanat---Gulberg/299699",
    //         "https://web.thebyte.app/en/store/Meal-Express/299613"
    //     ]
        // let resturants_data = []
        // for (let i = 0; i < resturants.length; i++) {
        //     let data = {
        //         "api_key": "8349870a68beae18c130cb16435d1d14",
        //         "user_id": resturants[i].split('/').pop()
        //     }
        //     ResturantApis.getResturant(data).then(data => {
        //         let final_data = data['data']
        //         final_data.resturant_url = resturants[i]
        //         resturants_data.push(data['data'])
        //         this.setState({
        //             resturants_data: resturants_data,
        //         })
        //     })

        // }
        // this.setState({
        //     is_loading: false
        // })
    //}

    responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1901 },
            items: 4
        },
        laptop: {
            breakpoint: { max: 1900, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1023, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 0,
        }
    };

    openResturant = (url) => {
        window.open(url, "_blank")
    }


    render() {
        let { row2, row1 ,is_loading } = this.state
        return (
            <>
                {
                    is_loading ?
                        <div className="tc">
                            <CircularProgress color="primary" />
                        </div>
                        :
                        <>
                            {
                                !this.props.is_desktop ?
                                    <div className="scrolling-wrapper">
                                    <>        

                                        <ScrollMenu  LeftArrow={LeftArrow} RightArrow={RightArrow}>
                                            {row1.map( item => ( 
                                                <Item
                                                title={item.store_name}
                                                logo={item.logo}
                                                />
                                            ))}
                                        </ScrollMenu>
                                        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                                            {row2.map( item => (
                                                <Item
                                                title={item.store_name}
                                                logo={item.logo}
                                                />
                                            ))}
                                        </ScrollMenu>


                                        </>
                                    </div>
                                    :
                                    <>        

                                        <ScrollMenu  LeftArrow={LeftArrow} RightArrow={RightArrow}>
                                            {row1.map( item => (
                                                <Item
                                                title={item.store_name}
                                                logo={item.logo}
                                                />
                                            ))}
                                        </ScrollMenu>
                                        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                                            {row2.map( item => (
                                                <Item
                                                title={item.store_name}
                                                logo={item.logo}
                                                />
                                            ))}
                                        </ScrollMenu>


                                    </>
                            }
                        </>
                }
            </>
        )
    }
}

export default Resturants

function Item({logo, title }) {
  
    return (
        <Card className="shadow-sm" style={{ width: '18.5rem', margin:'5px' }}>
            <Card.Img src={logo} className="img" alt="" />
             <Card.Title style={{padding:'5px'}}>   
                {title}
            </Card.Title>
        </Card>
    );
  }

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);
  
    return (
      <div disabled={isFirstItemVisible} onClick={() => scrollPrev()}       style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        userSelect: "none"
      }}  >
        &lt;
      </div>
    );
  }
  
  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  
    return (
      <div  id='arrow'  disabled={isLastItemVisible} onClick={() => scrollNext()}       style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        userSelect: "none"
      }} >
        &gt;
      </div>
    );
  }