import React from "react";
import { Button, Typography } from "@mui/material";
import "./index.scss";
import logo from "../../assets/logo.png";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div class="">
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
              <a href="/" class="navbar-brand">
                <img className="logo" src={logo} height="50" alt="CoolBrand" />
              </a>
              <button
                type="button"
                class="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav ms-auto">
                  {/* <a href="https://web.thebyte.app/en/" class="nav-item nav-link pe-4">About Us</a> */}
                  <a
                    href="http://web.thebyte.app/en/"
                    class="nav-item nav-link me-4"
                  >
                    Restaurants
                  </a>
                  {/* <a href="https://web.thebyte.app/en/" class="nav-item nav-link me-4">Our Locations</a> */}
                  {/* <a href="https://web.thebyte.app/en/" class="nav-item nav-link me-4">Contact</a> */}
                  <a
                    href="https://www.linkedin.com/jobs/byte-%28yc-s20%29-jobs-worldwide?f_C=42324610&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0"
                    class="nav-item nav-link me-4"
                  >
                    Careers
                  </a>
                  {/* <a href="https://web.thebyte.app/en/" class="nav-item nav-link me-4">Blog</a> */}
                  <a href="/privacy-policy" class="nav-item nav-link me-4">
                    Privacy Policy
                  </a>

                  {/* <Button variant="contained" className="button-style" onClick={()=> window.open("https://web.thebyte.app/en/", "_blank")}>Login</Button> */}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </>
    );
  }
}
export default Navigation;
